import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import Search from "../container/search";

const SearchPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Search" />
      <Search />
    </Layout>
  );
};

export default SearchPage;
