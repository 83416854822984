import React from "react";

import { NewsEventSlider } from "../components";

const Search = props => {
  // eslint-disable-next-line no-unused-vars
  const { loading, response: data, error, location } = props;

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            Search : Coming Soon
            <NewsEventSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
